import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  background: PropTypes.string,
  backgroundMobile: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
});

const preset = {
  background: '',
  backgroundMobile: '',
  title: '',
  content: '',
};

export default {
  shape,
  preset,
};
